import React, { useState, useRef } from 'react'
import { posts } from './store'
import uuid from 'uuidv4'
import '../static/files/App.css'
import uaParser from 'ua-parser-js'
import { view, store } from 'react-easy-state'

const a = 75
const b = (Math.sqrt(3) * a).toPrecision(5)
const c = 2 * a // hypotenuse

//     |\
//   b | \ c
//     |  \
//     |___\
//       a

const hovered = store({
  pathId: null
})

const HexText = ({ fieldName, side, postsItem, margin }) => {
  let currItem = store({ num: 0 })
  const field = postsItem[fieldName]
  let textContents

  if (typeof (field) === 'string') {
    textContents = field
  } else { // Assumed to be list of multiple texts
    textContents = field[currItem.num]
  }

  if (textContents instanceof Date) {
    textContents = 'on ' + textContents.toDateString()
  }

  return <text
      key={side}
      dy={`${(1 < side && side < 5) ? '0' : '0.6em'}`}
      textAnchor={`${(1 < side && side < 5) ? 'end' : 'start'}`}
      transform={
        `translate(${a} ${margin}) rotate(${60 * -side} ${a} ${b - margin})
            rotate(${(1 < side && side < 5) ? 180 : 0})`}
      style={{
        fill: 'black',
        fontFamily: 'Yanone Kaffeesatz',
        fontSize: fieldName === 'title' ? 'x-large' : 'medium',
        fontWeight: fieldName === 'title' ? '700' : '400'
      }}
      filter = {'url(#textFilter)'}
    >
      {textContents}
    </text>
}
const HexTextV = view(HexText)

const Hexagon = ({ x, y, postsItem }) => {
  // Hexagons can be broken down into triangles with angles 30 60 90 and sides x, 2x, and x*sqrt(3)
  const [patternId, setPatternId] = useState(uuid())
  const [pathId, setPathId] = useState(uuid())
  const [clipPathId, setClipPathId] = useState(uuid())
  const textFields = ['title', 'tags', 'statuses', 'times', 'locations'] // should be static on HexText
  const margin = 5
  return <>
    <pattern id={patternId} x="0" y="0" width="1" height="1">
      <image className={'bgimage'} height={2 * b} width={"100%"} preserveAspectRatio={"xMinYMid"} xlinkHref={postsItem.image}/>
    </pattern>

      <g className={'hextile'} transform={`translate(${x},${y + 1})`} >
        <a href={postsItem.link}
           onMouseEnter={() => {hovered.pathId = pathId}}
           onMouseLeave={() => {hovered.pathId = null}}
           onTouchEnd={(e) => {
             e.preventDefault()
             if (hovered.pathId == pathId){
               window.location = postsItem.link
             } else {
               hovered.pathId = pathId
             }
             // window.location = postsItem.link
           }}
        >
        <path id={pathId}
              className={'hexTile'}
              fill={postsItem.image ? `url(#${patternId})` : 'grey'}
              filter={hovered.pathId == pathId ? 'url(#blur)' : 'none'}
              stroke={'none'}
              d={`M${a} -1 h${c} l${a} ${b} l${-a} ${b} h${-c} l${-a} ${-b} Z`}
        >
        </path>
        <clipPath id={clipPathId}>
          <use href={`#${pathId}`}></use>
        </clipPath>
        <g clipPath={`url(#${clipPathId})`}>
          {textFields.map((fieldName, side) => {
            if (fieldName) return <HexTextV
              key={fieldName}
              fieldName={fieldName}
              side={side}
              postsItem={postsItem}
              margin={margin}
            />
          })}
        </g>
        <foreignObject x={0} y={0} width={4 * a} height={`${2*b}px`}>
          <div height={`${2*b}px`}>
            <div style={
              {
                shapeOutside: `polygon(
            0 0, ${a}px 0, 5px ${b}px, ${a}px ${2 * b}px, 0 ${2 * b}px
          )`,
                shapeMargin: `${3 * margin}px`,
                float: 'left',
                width: `${2 * a}`, height: 300
              }
            }/>
            <div style={
              {
                shapeOutside: `polygon(
              ${a}px 0,
              ${2 * a - 5}px ${b}px,
              ${a}px ${2 * b}px,
              ${2 * a}px ${2 * b}px,
              ${2 * a}px 0)`,
                shapeMargin: `${2 * margin}px`,
                float: 'right',
                width: `${2 * a}`,
                height: `${2*b}px`
              }
            }/>
            <div
              className={'intro'}
              style={{
                paddingTop: '2em', textAlign: 'justify', textShadow: '0 0 2px white, 0 0 4px white, 0 0 4px white',
                visibility: (hovered.pathId == pathId ? 'visible' : 'hidden'),
                height: `${2*b}px`
              }}>
              {postsItem['intro']}
            </div>
          </div>
        </foreignObject>
        </a>
      </g>
  </>
}
const HexagonV = view(Hexagon)

const ProfileMiniBack = () =>
  <path id={pathId} className={'hexTile'} fill={postsItem.image ? `url(#${patternId})` : 'grey'} stroke={'none'}
        d={`M0 -1
              h${3*a+2*c+ (xSpacing/2)}
              l${-a/3} ${b/3}
              h${-(3*a+2*c+ (xSpacing/2)) + a/3}
              Z`} />

const Profile = ({ x, y, postsItem, ySpacing=25 }) => {
  const patternId = 'profilePatternID'
  const pathId = 'profilePathID'
  const margin = 5
  const blurContrast = 0.7
  const xSpacing = 2 * ySpacing
  return <>
    <svg className={'profile'}>
    <pattern id={patternId} x="0" y="0" width="1" height="1">
      <image className={'bgimage'} height={2 * b} width="100%" preserveAspectRatio={"xMinYMid"} href={postsItem.image} />
    </pattern>
      <g className={'hextile'} transform={`translate(${x},${y + 1})`}>

        <path id={pathId} className={'hexTile'} fill={postsItem.image ? `url(#${patternId})` : 'grey'} stroke={'none'}
              d={`M0 -1
              h${3*a+2*c+ (xSpacing/2)}
              l${-a} ${b}
              h${-2*a-(xSpacing/2)}
              l${-a} ${b}
              h${-(c+a)}
              Z`}>
        </path>
      </g>
    </svg>

    <svg style={{position: 'fixed', zIndex:500, width:'600px', height:'45px'}}>
      <path className="hexTile" fill={`url(#${patternId})`} stroke="none" d="M0 -1
              h550
              l-25 43.3
              h-525
              Z"/>
    </svg>

    <div style={{position: 'fixed', margin: `${margin}`, zIndex:1000}}>

    <h1>Aaron Curtis</h1>
    <a className={'icon'} href={'mailto:web@aaroncurt.is'} style={{marginLeft: '3em'}}>
      <img src='/icons/email2.svg' />
    </a>
      <a className={'icon'} href={'/files/curtis_cv.pdf'}>
        <span>CV</span>
      </a>
    <a className={'icon'} href={'https://github.com/foobarbecue'} >
      <img src='/icons/gh.svg' />
    </a>
    <a className={'icon'} href={'https://www.zotero.org/aarongc'}>
      <span>Pubs</span>
    </a>
    <a className={'icon'} href={'https://stackexchange.com/users/306152/foobarbecue'}>
      <img src='/icons/se.svg' />
    </a>
    </div>
  </>
}
const ProfileHexagonV = view(Profile)

const HexRow = ({ xCount, xOffset, yOffset, y, xSpacing }) =>
  [...Array(xCount).keys()].map((x) => {
    const postsItem = posts.all.filter((post) => {
      if (post.hexCoords[0] === x && post.hexCoords[1] === y) {
        return post
      }
    })
    if (postsItem.length === 1) { // One post has these coordinates
      return <HexagonV
        key={x}
        x={x * (a * 6) + x * xSpacing + xOffset}
        y={yOffset}
        postsItem={postsItem[0]}
      /> //TODO add interest threshold
    }
  })
const HexRowV = view(HexRow)

const HexGrid = ({ xCount, yCount, ySpacing, data }) => {
  // x spacing is determined by y spacing so that there is an equilateral triangle between hexagon corners
  const xSpacing = 2 * ySpacing
  const yCoord = (n) => n * b + n * ySpacing / 2
  const maxXCoord = (n) => n * (c + 2 * a + xSpacing / 2)
  return     <svg id={'hextiles'} width={maxXCoord(xCount+1)} height={yCoord(yCount+1)}>
    {[...Array(yCount).keys()].map((n) => {
      if (n % 2) { // n is odd
        return <HexRowV
          key={`row-${n}`}
          y={n}
          xCount={xCount}
          xSpacing={xSpacing}
          xOffset={3 * a + xSpacing / 2}
          yOffset={yCoord(n)}/>
      } else {
        return <HexRowV
          key={`row-${n}`} y={n}
          y={n}
          xCount={xCount}
          xSpacing={xSpacing}
          xOffset={0}
          yOffset={yCoord(n)}/>
      }
    }
  )}
  </svg>
}

export default view(() => {
  const profilePicItem = {
    title: 'Aaron Curtis',
    locations: '',
    slug: '',
    statuses: [],
    times: [],
    intro: '',
    tags: [],
    link: '',
    image: '/thumb/me_extended.jpg',
    hexCoords: [2, 0]
  }
  const blurContrast = 0.7
  const browserIsSupported = ['Firefox','Chrome','Edge'].some((browser)=>window.navigator.userAgent.includes(browser))
  const parser = new uaParser()
  const isMobile = parser.getDevice().type == 'mobile'


  return <>
    <svg height={0} width={0}>
      <defs>
    <filter id={"textFilter"}>
      <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
      <feOffset dx="0" dy="0" result="offsetblur"/>
      <feFlood floodColor="white"/>
      <feComposite in2="offsetblur" operator="in"/>
      <feMerge>
        <feMergeNode/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>

    <filter id="blur">
      <feGaussianBlur stdDeviation={'2'}/>
      <feComponentTransfer>
        <feFuncR type="linear" slope={blurContrast} intercept={-(0.5 * blurContrast) + 0.7}/>
        <feFuncG type="linear" slope={blurContrast} intercept={-(0.5 * blurContrast) + 0.7}/>
        <feFuncB type="linear" slope={blurContrast} intercept={-(0.5 * blurContrast) + 0.7}/>
      </feComponentTransfer>
    </filter>
      </defs>
    </svg>
    <ProfileHexagonV x={0} y={0} postsItem={profilePicItem}></ProfileHexagonV>
    <div style={{position: 'absolute', background:'#b8beca', top: `${2*b}`, width: '100%', height: `${9*b}`}}></div>
    <HexGrid xCount={3} yCount={10} ySpacing={25}></HexGrid>
    {!browserIsSupported  && <div>This site only supports recent Firefox, Chrome, and Edge at the moment. I'm working on expanding support.</div>}
    {isMobile  && <div>This site doesn't support mobile devices yet. I'm working on it.</div>}
  </>
})
