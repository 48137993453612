import { store } from 'react-easy-state'

export const posts = {
  all : [
    {title: 'Afterflight',
      slug: 'afterflight',
      locations: 'Socorro, NM',
      statuses: ['archived'],
      times: [new Date(2016, 5, 29)],
      intro: 'Browser-based system for analysis and annotation of video and sensor data from drones which I wrote using Django and javascript in 2013.',
      tags: ['project'],
      link: '/afterflight.html',
      hexCoords: [0,4],
      image: '/thumb/afterflight.jpg'
    },

    {title: 'Volcanofiles interview',
      locations: 'Erebus',
      statuses: ['posted online'],
      times: [new Date(2011, 11, 28)],
      intro: "My friend and colleague Tehnuka Ilanko was kind enough to write about my work on Erebus in 2011, and include a bunch of Nial Peters' fantastic photography.",
      tags: ['link','media'],
      link: 'http://volcanofiles.com/2011/12/28/erebus-science-ice-caves/',
      image: '/thumb/volcanofiles2.jpg',
      hexCoords: [2,0]
    },

    {title: 'Frozen planet',
      locations: 'Erebus',
      slug: 'frozenplanet',
      statuses: ['released'],
      times: [],
      intro: 'We had a blast filming with BBC Frozen Planet on Erebus 2010. I\'m the guy rappelling and wriggling around with the Peli case. Nial Peters is the other one.',
      tags: ['link'],
      link: '/frozenplanet.html',
      image: '/thumb/frozenplanet.jpg',
      hexCoords: [0,1]
    },

    {title: 'Erebus caves DTS',
      locations: ['Erebus', 'NM Tech'],
      slug: 'dtsgrl',
      statuses: ['published'],
      times: [new Date([2011, 8, 17])],
      intro: 'We strung fiber-optic cables through an Erebus ice cave and turned them into spatial temperature sensors. We could see hot fumaroles and cold vents in the data, and it responded to the weather.',
      tags: ['1st author', 'paper'],
      link: '/paper/curtis2011-erebus_dts.pdf',
      image: '/thumb/dts.jpg',
      hexCoords: [1,1]
    },

    {title: 'Trail by Fire',
      locations: ['Chile', 'Peru'],
      slug: 'tbf',
      statuses: ['released'],
      times: [],
      intro: 'I traveled through South America to measure gases at 15 volcanoes, serving as the quadcopter architect and pilot. Don\'t miss the posts about each volcano (in "news").',
      tags: ['link'],
      link: 'https://trailbyfire.org',
      image: '/thumb/TBF.jpg',
      hexCoords: [0,2]
    },

    {title: 'Multirotors at LANL',
      locations: 'Los Alamos',
      slug: 'lanl',
      statuses: [],
      times: [],
      intro: 'I built and programmed unmanned aerial vehicles for remote sample collection and sensor placement at Los Alamos National Labs.',
      tags: ['link'],
      link: '/lanl_copters.html',
      image: '/thumb/lanlPlantChomp.jpg',
      hexCoords: [1,2]
    },

    {title: 'Global Glaciovolcanism',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: "I think a lot about fumarolic ice caves yet undiscovered. This led me to the question: 'How many volcanoes have perennial snow?' I couldn't find the answer, so I turned to MODIS and wrote a paper.",
      tags: ['1st author', 'paper'],
      link: '/paper/curtis2017-glaciovolcanism.pdf',
      image: './thumb/glaciovolcanism.jpg',
      hexCoords: [2,2]
    },

    {title: 'Expo 2007 article',
      locations: '',
      slug: 'expo07',
      statuses: [],
      times: [],
      intro: 'For several summers, I explored and mapped caves in Austria with the Cambridge University Caving Club. In 2007, I led the expedition and wrote this report afterwards.',
      tags: ['1st author', 'paper'],
      link: '/paper/curtis2007-expo2007.pdf',
      image: '/thumb/expo07.jpg',
      hexCoords: [0,3]
    },

    {title: 'BA Dissertation',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: 'The final project for my Geography BA (Cambridge University) in 2008, "Response of cave microclimates to external atmospheric forcing: Processes and implications investigated in Totes Gebirge, Austria"',
      tags: [],
      link: '/paper/curtis2008-atm_forcing.pdf',
      image: '/thumb/condensation.jpg',
      hexCoords: [1,4]
    },

    {title: 'Aerial Reel 2015',
      slug: 'aerialreel',
      locations: [],
      statuses: ['published'],
      times: [],
      intro: "It was a privilege to collaborate with Bob Martin (1953-2017). He put together this demo reel for me from a hard drive I gave him of unorganized video I'd shot featuring Erebus flights, tree climbing, kayaking in Alaska, climbing. It seems I'm a 'flyer'!",
      tags: ['project'],
      link: "https://www.youtube.com/watch?v=gMh8kM4o6tk",
      hexCoords: [1,6],
      image: '/thumb/aerialreel.jpg'
    },

    {title: 'PhD Dissertation',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: 'Dynamics and global relevance of fumarolic ice caves on Erebus Volcano, Antarctica',
      tags: [],
      link: '/paper/curtis2016-phd_diss.pdf',
      image: '/thumb/caveMelt.jpg',
      hexCoords: [1,3]
    },

    {title: 'Roving on Ice',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: 'Paper we wrote for IEEE Aerospace 2018',
      tags: ['1st author','IceWorm'],
      link: '/paper/curtisEtAl2018-iceworm.pdf',
      image: '/thumb/ieeeIceWorm.jpg',
      hexCoords: [0,5]
    },

    {title: 'Andes heavy isotopes',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: 'Schipper et al Isotopically (δ13C and δ18O) heavy volcanic plumes from Central Andean volcanoes: a field study',
      tags: ['3rd author','paper'],
      link: '/paper/tbf2017-isotopes.pdf',
      image: '/thumb/tbfIsotopes.jpg',
      hexCoords: [1,5]
    },
    {title: 'GSA 2011',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: 'A poster I presented at the Geological Society of America annual meeting in Minneapolis in 2011.',
      tags: ['1st author','poster'],
      link: '/gsa2011.html',
      image: '/thumb/gsa2011.jpg',
      hexCoords: [1,0]
    },
    {title: 'IAVCEI 2013',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: 'A poster I presented at the International Association of Volcanology and Chemistry of the Earth\'s Interior meeting in Kagoshima, Japan in 2013.',
      tags: ['1st author','poster'],
      link: '/iavcei2013.html',
      image: '/thumb/iavcei2013.jpg',
      hexCoords: [2,4]
    },
    {title: 'NatGeo Sea Caving',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: 'Intrepid Nic Barth was kind enough to take Nial and me down in his caves near Dunedin a couple of years ago on his National Geographic expedition. I made a little 3D model based on photos from the one day I was there and am delighted that it made it into the video.',
      tags: [''],
      link: '/sea_caving.html',
      image: '/thumb/seacaving.jpg',
      hexCoords: [0,6]
    },
    {title: 'Postdoc Poster 2019',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: '',
      tags: ['1st author','poster'],
      link: '/postdocPoster2019.html',
      image: '/thumb/postdocPoster2019.jpg',
      hexCoords: [0,7]
    },
    {title: 'EOS IceWorm Story',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: '',
      tags: [''],
      link: 'https://eos.org/articles/meet-iceworm-nasas-new-ice-climbing-robot',
      image: '/thumb/icewormCAscienceCtr.jpg',
      hexCoords: [1,7]
    },

    {title: 'JPL Press Release',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: '',
      tags: [''],
      link: 'https://www.jpl.nasa.gov/news/news.php?feature=6743',
      image: '/thumb/hutCaveISEE1.jpg',
      hexCoords: [2,6]
    },
    {title: 'Climbshare',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: "Crowdsourced interactive 3D climbing and bouldering guides.",
      tags: ['MeteorJS','VulcanJS','ReactJS'],
      link: 'https://github.com/foobarbecue/climbshare',
      image: '/thumb/climbshare.jpg',
      hexCoords: [1,8]
    },
    {title: 'Splor',
      locations: '',
      slug: '',
      statuses: [],
      times: [],
      intro: "Dig into plotted data, sync with video, and mark events. Runs in browser, entirely client-side.",
      tags: ['ReactJS'],
      link: 'https://github.com/foobarbecue/splor',
      image: '/thumb/splor.jpg',
      hexCoords: [0,8]
    },
  ]
}

export const flavorText =
  [
    'Are you hexcited about this website?'
  ]
